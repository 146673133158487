.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.evaluation-page header {
  height: 1.88rem;
  padding-left: 0.3rem;
  background: #fff;
}
.evaluation-page header img {
  width: 1.28rem;
  height: 1.28rem;
  margin-right: 0.88rem;
}
.evaluation-page .content {
  background: #fff;
  min-height: 3.24rem;
  padding: 0.3rem 0.14rem 0.3rem 0.3rem;
  margin-bottom: 0.34rem;
}
.evaluation-page .content .yd-textarea {
  padding: 0;
}
.evaluation-page .content .yd-textarea > textarea {
  height: 1.16rem;
  font-size: 0.28rem;
  color: #333;
}
.evaluation-page .content .yd-textarea > textarea::-webkit-input-placeholder {
  font-size: 0.28rem;
  color: #ccc;
}
.evaluation-page .content .yd-textarea > textarea::-moz-placeholder {
  font-size: 0.28rem;
  color: #ccc;
}
.evaluation-page .content .yd-textarea > textarea:-ms-input-placeholder {
  font-size: 0.28rem;
  color: #ccc;
}
.evaluation-page .content .yd-textarea > textarea::-ms-input-placeholder {
  font-size: 0.28rem;
  color: #ccc;
}
.evaluation-page .content .yd-textarea > textarea::placeholder {
  font-size: 0.28rem;
  color: #ccc;
}
.evaluation-page .content .img-list {
  margin-top: 0.3rem;
}
.evaluation-page .content .img-list .list-box {
  position: relative;
}
.evaluation-page .content .img-list .list-box .upload-img {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 12px;
  margin-right: 0.16rem;
  margin-bottom: 0.3rem;
  -o-object-fit: cover;
     object-fit: cover;
}
.evaluation-page .content .img-list .list-box .close-img {
  width: 0.28rem;
  height: 0.28rem;
  position: absolute;
  right: 0.1rem;
  top: -0.1rem;
}
.evaluation-page .content .img-list .upload-box {
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
}
.evaluation-page .content .img-list .upload-box input {
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  right: 0;
  top: 0;
  opacity: 0;
}
.evaluation-page .content .img-list .upload-box .upload-bg {
  width: 1.6rem;
  height: 1.6rem;
}
